import './App.css';
import logo from './ironneologo.png';

function App() {
  return (
    <div className="App">
      <img src={logo} />
    </div>
  );
}

export default App;
